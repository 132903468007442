/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "../../../../components/MKBox";
import MKTypography from "../../../../components/MKTypography";

// Material Kit 2 React examples
import HorizontalTeamCard from "../../../../examples/Cards/TeamCards/HorizontalTeamCard";

// Images
import team1 from "../../../../assets/images/team-5.jpg";
import team2 from "../../../../assets/images/bruce-mars.jpg";
import team3 from "../../../../assets/images/ivana-squares.jpg";
import team4 from "../../../../assets/images/ivana-square.jpg";
import aelchimImage from "../../../../assets/images/IMG_4444.JPG";


function Team() {
  return (
    <MKBox
      component="section"
      variant="gradient"
      bgColor="dark"
      position="relative"
      py={6}
      px={{ xs: 2, lg: 0 }}
      mx={-2}
    >
      <Container>
        <Grid container>
          <Grid item xs={12} md={8} sx={{ mb: 6 }}>
            <MKTypography variant="h3" color="white">
              The Team
            </MKTypography>
            <MKTypography variant="body2" color="white" opacity={0.8}>
              There&apos;s nothing I really wanted to do in life that I wasn&apos;t able to get good
              at. That&apos;s my skill.
            </MKTypography>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={12}>
            <MKBox mb={1}>
              <HorizontalTeamCard
                image={aelchimImage}
                name="Mike Vargas"
                position={{ color: "info", label: "Founder & CEO" }}
                description="I am committed to building applications that are unique, engaging, and useful. I make sure that everything we create is fun and has a great user experience. For us as long as the user is happy, I am happy."
              />
            </MKBox>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Team;
