/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "../../../../components/MKBox";

// Material Kit 2 React examples
import DefaultInfoCard from "../../../../examples/Cards/InfoCards/DefaultInfoCard";
import CenteredBlogCard from "../../../../examples/Cards/BlogCards/CenteredBlogCard";

// Images
import bgImage from "../../../../assets/images/4v5ltBf_600.png";

function Information() {
  return (
    <MKBox component="section" py={12}>
      <Container>
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={12} lg={6}>
            <Grid container justifyContent="flex-start">
              <Grid item xs={12} md={12}>
                <MKBox mb={5}>
                  <DefaultInfoCard
                    icon="public"
                    title="We Know Wednesday."
                    description="Wikipedia tells us Wednesday is the day of the week following Tuesday and preceding Thursday. According to international standard ISO 8601 it is the third day of the week. In countries that use the Sunday-first convention, and in the Jewish Hebrew calendar Wednesday is defined as the fourth day of the week. The name is derived from Old English Wōdnesdæg and Middle English Wednesdei, \u0022day of Woden\u0022, reflecting the pre-Christian religion practiced by the Anglo-Saxons. In other languages, such as the French mercredi or Italian mercoledì, the day's name is a calque of dies Mercurii \u0022day of Mercury\u0022. It has the most letters out of all the Gregorian calendar days. Other days just don't compare to Wednesdays. Trade on Wednesdays and spent the rest of your week with your friends or family, relaxing and having fun! No need to constantly check the price because even if the price is high, if it is not Wednesday you can't sell."
                  />
                </MKBox>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} lg={4} sx={{ ml: "auto", mt: { xs: 3, lg: 0 } }}>
            <CenteredBlogCard
              image={bgImage}
              title=""
              description="Checkout WED on Etherscan!"
              action={{
                type: "external",
                route: "https://etherscan.io/token/0x7848ae8f19671dc05966dafbefbbbb0308bdfabd",
                color: "info",
                label: "Etherscan",
              }}
            />
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Information;
