/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "../../../../components/MKBox";

// Material Kit 2 React examples
import DefaultInfoCard from "../../../../examples/Cards/InfoCards/DefaultInfoCard";
import CenteredBlogCard from "../../../../examples/Cards/BlogCards/CenteredBlogCard";

// Images
import bgImage from "../../../../assets/images/She-Hulk-logo.jpeg";

import { useAuthenticator } from '@aws-amplify/ui-react';

function Information() {

  const { authStatus } = useAuthenticator(context => [context.authStatus]);

  return (
    <MKBox component="section" py={12}>
      <Container>
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={12} lg={12}>
            <Grid container justifyContent="flex-start">
              <Grid item xs={12} md={12}>
                <MKBox mb={5}>
                  <DefaultInfoCard
                    icon="security"
                    title="*PLEASE READ THIS WHOLE PAGE BEFORE WATCHING THE MOVIE*"
                    description="I am so excited to be able to share this fan edit with those who have a Disney Plus membership! I have done this re-edit out of the love I have for Marvel and the excitement I have for the new content we’re getting. I very much liked SheHulk but, in my opinion, the series could have focused more on just Jennifer Walter's story and I could do with less goofiness (humor is subjective, and if you found it funny, that is great!). I am not a professional editor by any means but I have been a fan of fan edits since The Phantom Edit and following the Obi-Wan Kenobi Patterson Cut edit I wanted to give this a try for educational(learning) purposes. I want to be very clear, this is my own artistic interpretation of how this series could be put together as a movie, to make something that works better for me personally. This is all done as a fan, for the fans, and I am not making any money from it. I really hope you enjoy this almost 3 hr movie interpretation of the SheHulk Show. The current version is 720p and I am working on getting the 1080p version out ASAP."
                  />
                </MKBox>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} lg={12}>
            <Grid container justifyContent="flex-start">
              <Grid item xs={12} md={12}>
                <MKBox mb={5}>
                  <DefaultInfoCard
                    icon="gavel"
                    title="COPYRIGHT"
                    description="I do not own any of the material as this is just a fan edit. DO NOT watch or download this movie if you haven't paid for a Disney Plus subscription. Let’s make sure we’re supporting all the original artists on this show by not letting this become a means of pirating. Owning a Disney Plus subscription will give you access to the 9 episodes of the SheHulk show that have been repurposed for this fan edit. By clicking the links below and downloading the files you are confirming that you have a Disney Plus subscription and can access the entire series legally."
                  />
                </MKBox>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} lg={12}>
            <Grid container justifyContent="flex-start">
              <Grid item xs={12} md={12}>
                <MKBox mb={5}>
                  <DefaultInfoCard
                    icon="movie"
                    title="THE MOVIE"
                    description="Do not share this link publicly, so we can do our best to keep this edit out of the hands of movie pirates! Remember, this is for Disney Plus subscribers ONLY! And please go watch the show as well!"
                  />
                </MKBox>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} lg={12} sx={{ ml: "0", mt: { xs: 3, lg: 0 } }}>
            {authStatus !== 'authenticated' ?
                <CenteredBlogCard
                image={bgImage}
                title="Download"
                description="You need to sign up or sign in to access, do that, then come back"
                action={{
                    type: "internal",
                    route: "/projects/sign-in",
                    color: "info",
                    label: "sign in",
                }}
                />
                :
                <div style={{display:"flex"}} >
                <CenteredBlogCard
                image={bgImage}
                title="SheHulk (1080p)"
                description="Click below to download!"
                action={{
                    type: "external",
                    route: "https://d1k9azfeh9p04y.cloudfront.net/media/She-Hulk-1080p.mp4",
                    color: "info",
                    label: "download",
                }}
                />

                <CenteredBlogCard
                image={bgImage}
                title="SheHulk (720p)"
                description="Click below to download!"
                action={{
                    type: "external",
                    route: "https://d1k9azfeh9p04y.cloudfront.net/media/She-Hulk-720p.mp4",
                    color: "info",
                    label: "download",
                }}
                />
                </div>
            }
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Information;
