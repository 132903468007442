/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Kit 2 React components
import MKBox from "../../components/MKBox";
import MKTypography from "../../components/MKTypography";
import MKSocialButton from "../../components/MKSocialButton";

// Material Kit 2 React examples
import DefaultNavbar from "../../examples/Navbars/DefaultNavbar";
import DefaultFooter from "../../examples/Footers/DefaultFooter";
import FilledInfoCard from "../../examples/Cards/InfoCards/FilledInfoCard";

// Presentation page sections
import Counters from "../../pages/Presentation/sections/Counters";
import Information from "../../pages/Presentation/sections/Information";
import DesignBlocks from "../../pages/Presentation/sections/DesignBlocks";
import Pages from "../../pages/Presentation/sections/Pages";
import Testimonials from "../../pages/Presentation/sections/Testimonials";
import Download from "../../pages/Presentation/sections/Download";

// Presentation page components
import BuiltByDevelopers from "../../pages/Presentation/components/BuiltByDevelopers";

// Routes
import routes from "../../routes";
import footerRoutes from "../../footer.routes";

// Images
import bgImage from "../../assets/images/vincentiu-solomon.jpeg";

import { createUser } from '../../graphql/mutations'
import { getMe } from '../../graphql/queries'

import SignIn from "../../layouts/projects/sign-in";
import { useAuthenticator } from '@aws-amplify/ui-react';

import { Amplify, Auth, API, graphqlOperation } from 'aws-amplify';

var user = {};
var userSession : any;

function Presentation() {

  const { authStatus } = useAuthenticator(context => [context.authStatus]);

      try {
          Auth.currentSession().then((session) => {
              userSession = session;
              //console.log(userSession);
              getUser();
          });
      } catch(err) {
          console.log('ERROR:', err)
      }

  return (
    <>
    {authStatus !== 'authenticated' ?
      <DefaultNavbar
        routes={routes}
        action={{
          type: "internal",
          route: "/projects/sign-in",
          label: "sign in",
          color: "default",
          routeComponent: <SignIn />,
        }}
        transparent
        light />
        :
        <DefaultNavbar
                routes={routes}
                action={{
                  type: "internal",
                  route: "/projects/sign-out",
                  label: "sign out",
                  color: "default",
                  routeComponent: <SignIn />,
                }}
                transparent
                light />}
      <MKBox
        minHeight="75vh"
        width="100%"
        sx={{
          backgroundImage: `url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "top",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <Grid container item xs={12} lg={7} justifyContent="center" mx="auto">
            <MKTypography
              variant="h1"
              color="white"
              mt={-6}
              mb={1}
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
            >
              aelchim's fun stuff{" "}
            </MKTypography>
            <MKTypography
              variant="body1"
              color="white"
              textAlign="center"
              px={{ xs: 6, lg: 12 }}
              mt={1}
            >
              A collection and compilation of all the stuff I do (^_^)
            </MKTypography>
          </Grid>
        </Container>
      </MKBox>
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
          backdropFilter: "saturate(200%) blur(30px)",
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >

        <Information />

      </Card>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

  async function getUser() {
      try {
        const savedUser : any = await API.graphql(graphqlOperation(getMe))
        if (savedUser.data.getMe) {
          user = savedUser.data.getMe
        } else {
          addUser()
        }
      } catch (err) { console.log(err) }
    }

      async function addUser() {
        try {
        const user: any = {
              username: userSession.idToken.payload["cognito:username"],
              id: userSession.idToken.payload["sub"],
              cognitoId: userSession.idToken.payload["sub"],
              registered: false,
              profilePic: "profilePic.png",
              ethAddress: "",
              about: "",
              wed: 0,
              eth: 0,
              alias: "",
              par: 0,
            };
          await API.graphql(graphqlOperation(createUser, user))
        } catch (err) {
          console.log('error creating user:', err)
        }
      }

export default Presentation;
