/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "../../../../components/MKBox";

// Material Kit 2 React examples
import DefaultInfoCard from "../../../../examples/Cards/InfoCards/DefaultInfoCard";
import CenteredBlogCard from "../../../../examples/Cards/BlogCards/CenteredBlogCard";

// Images
import bgImage from "../../../../assets/images/qurate.png";

function Information() {
  return (
    <MKBox component="section" py={12}>
      <Container>
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={12} lg={6}>
            <Grid container justifyContent="flex-start">
              <Grid item xs={12} md={12}>
                <MKBox mb={5}>
                  <DefaultInfoCard
                    icon="public"
                    title="Software and Us"
                    description="We love building and creating things! We are driving to finding what applications or software can make life easier and better for all. We take innovation seriously and strive for thinking how would make the next best thing. Creating apps is our specialty but we also delve into whatever we think needs to be looked at. We are not scared to go into the unknown of the tech world so that we can learn and build great things. Everyday we look ahead into what will come next. We release it is not easy to grab something, an idea or product, from the future and bring it here but that is what we do."
                  />
                </MKBox>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} lg={4} sx={{ ml: "auto", mt: { xs: 3, lg: 0 } }}>
            <CenteredBlogCard
              image={bgImage}
              title="Qurated"
              description="Our goal for Qurated is to give everyone a chance to rate and review every page on the internet!"
              action={{
                type: "external",
                route: "https://chrome.google.com/webstore/detail/qurated/knddmenbhkagdikdkdajlkejdomdmnmn",
                color: "info",
                label: "find out more",
              }}
            />
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Information;
