/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "../../../../components/MKBox";

// Material Kit 2 React examples
import DefaultInfoCard from "../../../../examples/Cards/InfoCards/DefaultInfoCard";

// Images
import coinbase from "../../../../assets/images/logos/gray-logos/logo-coinbase.svg";
import nasa from "../../../../assets/images/logos/gray-logos/logo-nasa.svg";
import netflix from "../../../../assets/images/logos/gray-logos/logo-netflix.svg";
import pinterest from "../../../../assets/images/logos/gray-logos/logo-pinterest.svg";
import spotify from "../../../../assets/images/logos/gray-logos/logo-spotify.svg";
import vodafone from "../../../../assets/images/logos/gray-logos/logo-vodafone.svg";

function Featuring() {
  return (
    <MKBox component="section" bgColor="light" pt={3} pb={8}>
      <Container>
        <Grid container justifyContent="center" sx={{ textAlign: "center" }}>
          <Grid item xs={12} md={3}>
            <DefaultInfoCard
                icon="computer"
                title="Wednesday"
                description="It can only be sent on Wednesdays UTC. Some might ask why I built a coin that only works on Wednesday, I say: Because Wednesday (plus I never could get the hang of Thursdays)."
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <DefaultInfoCard
                icon="favorite"
                title="Passion"
                description="We love Wednesday as much as, or maybe even more so than the next person. We built Wednesday Coin as an arbritary method of celebrating Wednesdays!"
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <DefaultInfoCard
                icon="diamond"
                title="Design"
                description="Contract is open source and has been vetted. We have tested it against other days such as Friday and Tuesday and it beat them all."
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <DefaultInfoCard
                icon="settings"
                title="Support"
                description="If it's not Wednesday, don't count on it! We believe all business should be done on Wednesdays. But we do update regularly on our subreddit."
            />
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Featuring;
