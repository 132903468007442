// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import GitHubIcon from "@mui/icons-material/GitHub";
import YouTubeIcon from "@mui/icons-material/YouTube";

// Material Kit 2 React components
import MKTypography from "./components/MKTypography";

// Images
import logoCT from "./assets/images/logo-ct-dark.png";

const date = new Date().getFullYear();

export default {
  brand: {
    name: "aelchim",
    image: logoCT,
    route: "/",
  },
  socials: [
    {
      icon: <TwitterIcon />,
      link: "https://twitter.com/aelchim",
    },
    {
      icon: <GitHubIcon />,
      link: "https://github.com/aelchim",
    },
    {
      icon: <YouTubeIcon />,
      link: "https://www.youtube.com/@aelchim-music",
    },
  ],
  menus: [
    {
      name: "company",
      items: [
        { name: "about us", href: "https://www.aelchim.com/presentation" },
        { name: "blog", href: "https://www.aelchim.com/blog" },
      ],
    },
    {
      name: "resources",
      items: [
        { name: "Wed Design", href: "https://www.creative-tim.com/product/material-kit-react" },
        { name: "images", href: "https://unsplash.com/" },
      ],
    },
    {
      name: "help & support",
      items: [
        { name: "contact me", href: "mailto:aelchim@gmail.com" },
        { name: "contact Volta Software", href: "mailto:admin@voltasoftware.net" },
        { name: "contact for WED", href: "mailto:admin@wednesdaycoin.io" },
      ],
    },
    {
      name: "legal",
      items: [
        { name: "terms & conditions", href: "https://www.aelchim.com/volta-software/terms" },
        { name: "privacy policy", href: "https://www.aelchim.com/volta-software/privacy" },
        { name: "licenses (EULA)", href: "https://www.aelchim.com/volta-software/license" },
      ],
    },
  ],
  copyright: (
    <MKTypography variant="button" fontWeight="regular">
      All rights reserved. Copyright &copy; {date} by{" "}
      <MKTypography
        component="a"
        href="https://www.creative-tim.com"
        target="_blank"
        rel="noreferrer"
        variant="button"
        fontWeight="regular"
      >
        aelchim
      </MKTypography>
      .
    </MKTypography>
  ),
};
