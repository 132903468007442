/**
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/** 
  All of the routes for the Material Kit 2 React React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Navbar.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `name` key is used for the name of the route on the Navbar.
  2. The `icon` key is used for the icon of the route on the Navbar.
  3. The `collapse` key is used for making a collapsible item on the Navbar that contains other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  4. The `route` key is used to store the route location which is used for the react router.
  5. The `href` key is used to store the external links location.
  6. The `component` key is used to store the component of its route.
  7. The `dropdown` key is used to define that the item should open a dropdown for its collapse items .
  8. The `description` key is used to define the description of
          a route under its name.
  9. The `columns` key is used to define that how the content should look inside the dropdown menu as columns,
          you can set the columns amount based on this key.
  10. The `rowsPerColumn` key is used to define that how many rows should be in a column.
*/

// @mui material components
import Icon from "@mui/material/Icon";

// @mui icons
import GitHubIcon from "@mui/icons-material/GitHub";
import TwitterIcon from "@mui/icons-material/Twitter";

// Pages
import AboutUs from "./layouts/pages/landing-pages/about-us";
import ContactUs from "./layouts/pages/landing-pages/contact-us";
import Author from "./layouts/pages/landing-pages/author";
import SignIn from "./layouts/pages/authentication/sign-in";

// Sections
import PageHeaders from "./layouts/sections/page-sections/page-headers";
import Features from "./layouts/sections/page-sections/featuers";
import Navbars from "./layouts/sections/navigation/navbars";
import NavTabs from "./layouts/sections/navigation/nav-tabs";
import Pagination from "./layouts/sections/navigation/pagination";
import Inputs from "./layouts/sections/input-areas/inputs";
import Forms from "./layouts/sections/input-areas/forms";
import Alerts from "./layouts/sections/attention-catchers/alerts";
import Modals from "./layouts/sections/attention-catchers/modals";
import TooltipsPopovers from "./layouts/sections/attention-catchers/tooltips-popovers";
import Avatars from "./layouts/sections/elements/avatars";
import Badges from "./layouts/sections/elements/badges";
import BreadcrumbsEl from "./layouts/sections/elements/breadcrumbs";
import Buttons from "./layouts/sections/elements/buttons";
import Dropdowns from "./layouts/sections/elements/dropdowns";
import ProgressBars from "./layouts/sections/elements/progress-bars";
import Toggles from "./layouts/sections/elements/toggles";
import Typography from "./layouts/sections/elements/typography";

//Aelchim Site
import AboutVoltaSoftware from "./layouts/volta-software/about";
import AboutWednesdayCoin from "./layouts/wednesday-coin/about";
import SheHulk from "./layouts/edits/she-hulk";

const routes = [
  {
    name: "Volta Software",
    icon: <Icon>dashboard</Icon>,
    columns: 1,
    rowsPerColumn: 2,
    collapse: [
      {
        name: "Info",
        collapse: [
          {
            name: "about",
            route: "/volta-software/about",
            component: <AboutVoltaSoftware />,
          },
          {
            name: "contact us",
            href: "mailto:hello@voltasoftware.net",
          },
          {
            name: "Twitter",
            href: "https://twitter.com/VoltaSoftware",
          },
        ],
      },
      {
        name: "Projects",
        collapse: [
          {
            name: "Qurated",
            href: "https://chrome.google.com/webstore/detail/qurated/knddmenbhkagdikdkdajlkejdomdmnmn",
          },
          {
            name: "KATZ Radio",
            href: "https://apps.apple.com/us/app/katz-radio/id1454762407",
          },
          {
            name: "Spirit Seance",
            href: "https://www.amazon.com/Volta-Software-LLC-Spirit-Seance/dp/B01MU1YKPU"
          },
        ],
      },
    ],
  },
  {
    name: "WednesdayCoin",
    icon: <Icon>view_day</Icon>,
    columns: 1,
    rowsPerColumn: 2,
    collapse: [
      {
        name: "Info",
        collapse: [
          {
            name: "About",
            route: "/wednesday-coin/about",
            component: <AboutWednesdayCoin />,
          },
          {
            name: "Telegram",
            href: "https://t.me/WednesdayChat",
          },
          {
            name: "SubReddit",
            href: "https://www.reddit.com/r/WednesdayCoin/",
          },
          {
            name: "Twitter",
            href: "https://twitter.com/WednesdayCoin",
          },
        ],
      },
      {
        name: "Projects",
        collapse: [
          {
            name: "WednesdayClub",
            href: "https://wednesdayclub.app",
          },
          {
            name: "WednesdayBot",
            href: "https://t.me/WEDClubBot",
          }
        ],
      },
    ],
  },
  {
    name: "aelchim",
    icon: <Icon>article</Icon>,
    columns: 1,
    rowsPerColumn: 4,
    collapse: [
    {
        name: "Games",
        collapse: [
            {
                name: "aelchim.itch.io",
                href: "https://aelchim.itch.io",
            }
        ],
      },
      {
        name: "Edits",
        collapse: [
          {
            name: "She-Hulk",
            route: "/edits/she-hulk",
            component: <SheHulk />,
          },
        ],
      },
      {
        name: "YouTube",
        collapse: [
          {
            name: "aelchim-music",
            href: "https://www.youtube.com/@aelchim-music",
          }
        ],
      },
      {
        name: "Podcasts",
        collapse: [
          {
            name: "SuperVargas",
            href: "https://anchor.fm/supervargas",
          },
        ],
      },
      {
        name: "Socials",
        collapse: [
          {
            name: "twitter",
            href: "https://www.twitter.com/aelchim",
          },
          {
            name: "github",
            href: "https://www.github.com/aelchim",
          },
        ],
      },
    ],
  },
];

export default routes;
