/* eslint-disable react/jsx-no-duplicate-props */
/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";

// Material Kit 2 React components
import MKBox from "../../../../components/MKBox";
import MKTypography from "../../../../components/MKTypography";
import MKInput from "../../../../components/MKInput";
import MKButton from "../../../../components/MKButton";

// Images
import macbook from "../../../../assets/images/macbook.png";

function Newsletter() {
  return (
    <MKBox component="section" pt={6} my={6}>
      <Container>
        <Grid container alignItems="center">
          <Grid item align="center" sx={12} md={12} sx={{ ml: { xs: 0, lg: 3 }, mb: { xs: 12, md: 0 } }}>
            <MKTypography variant="h4">ABOUT WEDNESDAY COIN</MKTypography>
            <br/>
            <MKTypography
                variant="body2"
                component={Link}
                href="https://anchor.fm/wizardofdapps/episodes/Episode-11-Wednesday-Coin-with-Mike-Vargas-e4l90l"
                target="_blank"
                rel="noreferrer"
                color="info"
            >
            Listen to our interview on Wizard of DApps!
            </MKTypography>
            <br/>
            <br/>
            <MKTypography
                 variant="body2"
                 component={Link}
                 href="https://youtu.be/gGZllmr_Qh4?t=1344"
                 target="_blank"
                 rel="noreferrer"
                 color="info"
            >
            Watch the CryptoZombie Feature on YouTube!
            </MKTypography>
            <br/>
            <br/>
            <MKTypography variant="body" mb={3}>
              Key features of our coin
            </MKTypography>
            <br/>
            <br/>
            <MKTypography variant="body2" mb={3}>
              Contract Address: 0x7848ae8f19671dc05966dafbefbbbb0308bdfabd
            </MKTypography>
            <MKTypography variant="body2" mb={3}>
              Symbol: WED
            </MKTypography>
            <MKTypography variant="body2" mb={3}>
              Decimal: 18
            </MKTypography>
            <MKTypography variant="body2" mb={3}>
                Total Supply: 21 Billion
            </MKTypography>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Newsletter;
