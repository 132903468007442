/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "../../../../components/MKBox";
import MKTypography from "../../../../components/MKTypography";

// Material Kit 2 React examples
import HorizontalTeamCard from "../../../../examples/Cards/TeamCards/HorizontalTeamCard";

// Images
import team1 from "../../../../assets/images/team-5.jpg";
import team2 from "../../../../assets/images/bruce-mars.jpg";
import team3 from "../../../../assets/images/ivana-squares.jpg";
import team4 from "../../../../assets/images/ivana-square.jpg";
import aelchimImage from "../../../../assets/images/IMG_4444.JPG";


function Contact() {
return (
    <MKBox component="section" bgColor="light" pt={6} my={6}>
      <Container>
        <Grid container alignItems="center">
          <Grid item align="center" sx={12} md={12} sx={{ ml: { xs: 0, lg: 3 }, mb: { xs: 12, md: 0 } }}>
            <MKTypography variant="h4">Contact</MKTypography>
            <br />
            <MKTypography variant="body2" mb={3}>
              Lets get in touch. Message us on Twitter, come visit our SubReddit, and join our Telegram! Links in the header above.
            </MKTypography>
            <MKTypography variant="body2" mb={3}>
              If you feel inclined to Donate (will be used only for funding WED things)
            </MKTypography>
            <MKTypography variant="body2" mb={3}>
              ETH: 0x05C73b2f42AE86C69F9A9fCB0d6de75821117bb4
            </MKTypography>
            <MKTypography variant="body2" mb={3}>
              BTC: 1JFBzJ2G3cYrMGvU2v5CDwNr75yY4hY1B3
            </MKTypography>
            <MKTypography variant="body2" mb={3}>
                LTC: LYQmuV3dpWLxjn5RSkZZ3ZFJLuAUBEQENu
            </MKTypography>
            <MKTypography variant="body2" mb={3}>
                DOGE: DDQTNAaVhXmKqJkTwmPi6J4ySxy4kPTJNJ
            </MKTypography>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Contact;
