/**
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";

// react-router-dom components
import { Link, Redirect, useNavigate, Navigate } from "react-router-dom";

import SignIn from "../../../layouts/projects/sign-in";

// @mui material components
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
import Grid from "@mui/material/Grid";
import MuiLink from "@mui/material/Link";

// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import GitHubIcon from "@mui/icons-material/GitHub";
import GoogleIcon from "@mui/icons-material/Google";

// Material Kit 2 React components
import MKBox from "../../../components/MKBox";
import MKTypography from "../../../components/MKTypography";
import MKInput from "../../../components/MKInput";
import MKButton from "../../../components/MKButton";

// Material Kit 2 React example components
import DefaultNavbar from "../../../examples/Navbars/DefaultNavbar";
import SimpleFooter from "../../../examples/Footers/SimpleFooter";

// Material Kit 2 React page layout routes
import routes from "../../../routes";

// Images
import bgImage from "../../../assets/images/bg-sign-in-basic.jpeg";

import { Authenticator, useAuthenticator } from '@aws-amplify/ui-react'
import { Amplify, Auth, API, graphqlOperation } from 'aws-amplify';
import '@aws-amplify/ui-react/styles.css';
import aws_exports from '../../../aws-exports';
Amplify.configure(aws_exports);

function SignOut() {
  const { authStatus, signOut } = useAuthenticator(context => [context.authStatus]);

    const navigate = useNavigate();
    if (authStatus === 'authenticated') {
        Auth.signOut();
        return <Navigate replace to="/projects/sign-in" />;
      }

  return (
    <>
    {authStatus !== 'authenticated' ?
      <DefaultNavbar
        routes={routes}
        action={{
          type: "internal",
          route: "/projects/sign-in",
          label: "sign in",
          color: "info",
          routeComponent: <SignIn />,
        }}
        sticky />
        :
        <DefaultNavbar
                routes={routes}
                action={{
                  type: "internal",
                  route: "/projects/sign-out",
                  label: "sign out",
                  color: "info",
                  routeComponent: <SignOut />,
                }}
                sticky />}
      <MKBox
        position="absolute"
        top={0}
        left={0}
        zIndex={-1}
        width="100%"
        minHeight="100vh"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6)
            )}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      />
      <Authenticator>
      </Authenticator>
      <MKBox width="100%" position="absolute" zIndex={2} bottom="1.625rem">
        <SimpleFooter light />
      </MKBox>
    </>
  );
}

export default SignOut;
